import Headliner from './Headliner';
import RecentProjects from './RecentProjects';
import * as React from 'react';
import {CMSData} from '../@types/CMSData';
import SkewedPanel from './SkewedPanel';
import ContactPanel from './ContactPanel';

export default function ZvanMainPage({data}: {data: CMSData}) {
  return (
    <>
      <SkewedPanel variant={'dark'} noTopMargin={true}>
        <Headliner
          variant={'dark'}
          title={'Izdelava avtodomov'}
          exposedText={'po meri'}
          items={[
            'Vi pripeljete vozilo, mi ga predelamo v dom',
            '100% se prilagodimo potrebam stranke',
            'Ročna izdelava pohištva',
            'Kvalitetni materiali',
            'Homologacijo opravimo mi',
          ]}
          linkTo={'/storitve#vse'}
        />
      </SkewedPanel>
      <SkewedPanel variant={'light'}>
        <Headliner
          title={'Posebne predelave'}
          exposedText={'tovornih vozil'}
          variant={'light'}
          items={[
            'Izdelava podov in sten tovornih vozil',
            'Premične garaže za motor',
            'Promocijska vozila',
            'Potujoča pekarna',
            'Obnova in servis avtodomov',
          ]}
          linkTo={'/storitve#vse'}
        />
      </SkewedPanel>
      <SkewedPanel variant={'dark'}>
        <RecentProjects projects={data.allProject.nodes}></RecentProjects>
      </SkewedPanel>
      <ContactPanel contactInfo={data.allContactInfo.nodes[0]}/>
    </>
  );
}
