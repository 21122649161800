import {Box, Container, Grid, Typography, useTheme} from '@mui/material';
import * as React from 'react';
import {Project} from '../@types/CMSData';
import HeadlinerLink from './HeadlinerLink';
import {Link} from 'gatsby';
import useCloudStorageImage from '../hooks/useCloudStorageImage';

function RecentProjects({projects}: {projects: Project[]}) {
  const createStorageUrl = useCloudStorageImage();
  return (
    <>
      <Container maxWidth={'xl'}>
        <Grid container justifyContent='center' spacing={5}>
          <Grid item xs={12}>
            <Typography variant={'h1'} color={'white'} align={'center'} sx={{p: '20px 0'}}>
              Projekti
            </Typography>
          </Grid>
          <Grid item xs={12} alignSelf={'center'}>
            <Grid container justifyContent='center' spacing={10}>
              {projects.sort((a, b) => b.order - a.order).filter(project => project.isFeatured).slice(0, 3).map((project, i) => (
                <Grid key={i} item xs={12} md={6} lg={4}>
                  <Link to={`/projekti#${project.title}`} style={{textDecoration: 'none'}}>
                    <Grid container direction={'column'} justifyContent={'center'} spacing={2} sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      '&:hover': {
                        '& .projectImageSeparator': {
                          maxWidth: '200px',
                          filter: 'none',
                        },
                        '& .projectImage': {
                          filter: 'none',
                        },
                      },
                    }}>
                      <Grid item xs={12}>
                        <Box
                          className={'projectImage'}
                          sx={{
                            height: '300px',
                            width: 'auto',
                            margin: 'auto',
                            backgroundImage: `url('${createStorageUrl(project.featuredImage)}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            transitionDuration: '0.3s',
                            filter: `saturate(50%)`,
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={'body1'} fontSize={'16px'} color={'white'} fontWeight={600}
                                    sx={{m: 'auto'}}>
                          {project.title}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Box className='projectImageSeparator' sx={{
                          maxWidth: '50px',
                          transitionDuration: '0.3s',
                          borderBottom: '3px solid ' + useTheme().palette.secondary.main,
                        }} />
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item>
            <HeadlinerLink color={'white'} text={'Vsi naši projekti...'}
                           linkTo={'/projekti#vsi'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default RecentProjects;
