import {useCallback} from 'react';

export default function useCloudStorageImage() {
  return useCallback(
    (imagePath: string) => {
      encodeURIComponent(imagePath);
      return process.env.IMAGE_STORAGE_URL?.replace('{{IMAGE_PATH}}', encodeURIComponent(imagePath)) ?? imagePath;
    },
    [],
  );
  ;
}
