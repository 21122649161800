import * as React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Helmet} from 'react-helmet';

type ImageDataType = {
  images: {
    fallback: {
      src: string;
    };
  };
  width: number;
  height: number;
};

type SeoMetaDataQuery = {
  site: {
    siteMetadata: {
      title: string
      description: string
      siteUrl: string
      og: {
        siteName: string
      }
    }
  },
  featuredImage: {
    childImageSharp: {
      gatsbyImageData: ImageDataType
    }
  }
}

export type SeoProps = {
  /**
   * Path on which this site is available. e.g.: /contacts
   */
  sitePath: string;
  /**
   * Meta description. If not given, the one from the gatsby-config will be used.
   */
  description?: string;
  /**
   * Page title. If not given, the one from the gatsby-config will be used.
   */
  title?: string;
  /**
   * Meta keywords, will only be used if given.
   */
  keywords?: string;
  /**
   * Featured image, the data type should match the return type of `gatsbyImageData`.
   * If not given, the default image will be used.
   */
  featuredImage?: ImageDataType;
};
export default function Seo(props: SeoProps) {
  const {site, featuredImage} =
    useStaticQuery<SeoMetaDataQuery>(graphql`
      query SeoMetaData {
        site {
          siteMetadata {
            title
            description
            siteUrl
            og {
              siteName
            }
          }
        }
        featuredImage: file(
          absolutePath: { glob: "**/src/assets/featured-image.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 1200)
          }
        }
      }
    `);

  const ogImage =
    props.featuredImage ??
    (featuredImage?.childImageSharp
      ?.gatsbyImageData as unknown as ImageDataType);

  // determine title and description
  const title = props.title ?? site?.siteMetadata?.title;
  const description = props.description ?? site?.siteMetadata?.description;
  const metas = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'og:image',
      content: ogImage.images.fallback.src,
    },
    {
      name: 'og:image:width',
      content: `${ogImage.width}`,
    },
    {
      name: 'og:image:height',
      content: `${ogImage.height}`,
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:title',
      content: title,
    },
    {
      name: 'og:description',
      content: description,
    },
    {
      name: 'og:site_name',
      content: site!.siteMetadata!.og!.siteName,
    },
    {
      name: 'og:url',
      content: `${site?.siteMetadata?.siteUrl}${props.sitePath}`,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image',
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:image',
      content: ogImage.images.fallback.src,
    },
  ];

  // If we have keywords, then add it
  if (props.keywords) {
    metas.push({
      name: 'keywords',
      content: props.keywords,
    });
  }

  return (
    <Helmet>
      <html lang='sl-si' />
      <meta charSet='utf-8' />
      <title>{title}</title>
      {metas.map(meta => (
        <meta key={meta.name} name={meta.name} content={meta.content} />
      ))}
    </Helmet>
  );
}
