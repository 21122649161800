import {Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme} from '@mui/material';
import BulletPointIcon from '../assets/bullet-point-icon.svg';
import * as React from 'react';
import useMobile from './useMobile';
import HeadlinerLink from './HeadlinerLink';

function Headliner({
                     title,
                     exposedText,
                     items,
                     variant,
                     linkTo,
                   }: {
  title: string;
  exposedText: string;
  items: string[];
  variant: 'light' | 'dark';
  linkTo: string
}) {
  const theme = useTheme();
  const isMobile = useMobile();
  const textColor = variant === 'dark' ? theme.palette.background.paper : theme.palette.primary.dark;

  return (
    <Container maxWidth={'xl'}>
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        alignItems={'center'}
        justifyContent={'center'}
        spacing={3}
        sx={{p: '20px 5px'}}
      >
        <Grid item sx={{textAlign: isMobile ? 'center' : 'right'}} sm={12} md={6}>
          <Grid container direction={'column'} alignItems={'right'}>
            <Typography variant={'h1'} color={textColor} sx={{float: isMobile ? 'none' : 'right'}}>
              {title}
            </Typography>
            <Typography variant={'h1'} color={theme.palette.secondary.main}>
              {exposedText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{textAlign: 'left'}} sm={12} md={6}>
          <List>
            {items.map((item, i) => (
              <ListItem key={i}>
                <ListItemIcon sx={{minWidth: 35}}>
                  <img src={BulletPointIcon} style={{width: '25px', height: 'auto'}} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography color={textColor} variant={'body1'}>
                      {item}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item>
          <HeadlinerLink linkTo={linkTo} color={textColor} text={'Več o tem...'} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Headliner;
