import {useCallback, useState} from 'react';

export default function useExpirationBoolean(timeout: number): [boolean, () => void] {
  const [state, setState] = useState(false);
  const expirationCallback = useCallback(
    () => {
      setState(true);
      const timeoutRef = setTimeout(() => setState(false), timeout);
      return () => clearTimeout(timeoutRef);
    },
    [timeout],
  );

  return [state, expirationCallback];
}
