import {Container, Grid, styled, Typography} from '@mui/material';
import * as React from 'react';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactForm from './ContactForm';
import useMobile from './useMobile';
import {ContactInfo} from '../@types/CMSData';
import {useFormatPhone} from '../hooks/useFormatPhone';

export default function ContactPanel({contactInfo}: {contactInfo: ContactInfo}) {
  const ContactHeading = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  }));
  const isMobile = useMobile();
  const formattedPhone = useFormatPhone(contactInfo.phone);

  const mapsUrl = `https://maps.google.com/maps?q=${encodeURIComponent(contactInfo.address + ', ' + contactInfo.city)}`;

  return (
    <Container maxWidth={'xl'}>
      <Grid container sx={{p: '20px 5px'}} direction={'column'} alignItems={'stretch'} spacing={isMobile ? 3 : 10}>
        <Grid item xs={12} alignSelf={'center'} id={'kontakt'}>
          <Typography variant={'h1'}>Kontakt</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5} justifyContent={'space-evenly'}>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} direction={'column'} alignItems={'center'}>
                <Grid item>
                  <ContactHeading variant={'body1'}><EmailIcon sx={{mr: 1}} />Email</ContactHeading>
                </Grid>
                <Grid item>
                  <a href={`mailto:${contactInfo.email}`} style={{textDecoration: 'none'}} target={'_blank'}>
                    <Typography variant={'body1'}>{contactInfo.email}</Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} direction={'column'} alignItems={'center'}>
                <Grid item>
                  <ContactHeading variant={'body1'}><LocalPhoneIcon sx={{mr: 1}} />Telefon</ContactHeading>
                </Grid>
                <Grid item>
                  <a href={`tel:${contactInfo.phone}`} style={{textDecoration: 'none'}}>
                    <Typography variant={'body1'}>{formattedPhone}</Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={1} direction={'column'} alignItems={'center'}>
                <Grid item>
                  <ContactHeading variant={'body1'}><LocationOnIcon sx={{mr: 1}} />Naslov</ContactHeading>
                </Grid>
                <Grid item>
                  <a href={mapsUrl} style={{textDecoration: 'none'}} target={'_blank'}>
                    <Typography variant={'body1'}>{contactInfo.address}</Typography>
                  </a>
                </Grid>
                <Grid item>
                  <a href={mapsUrl} style={{textDecoration: 'none'}} target={'_blank'}>
                    <Typography variant={'body1'}>{contactInfo.city}</Typography>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={6} order={{xs: 2, md: 1}}>
              <iframe style={{borderRadius: '10px'}} width='100%' height='600' id='gmap_canvas'
                      src={`${mapsUrl}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                      frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0'></iframe>
            </Grid>
            <Grid item xs={12} md={6} order={{xs: 1, md: 2}}>
              <ContactForm />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
