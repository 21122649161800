import {styled, Theme, Typography} from '@mui/material';
import * as React from 'react';
import {SxProps} from '@mui/system';
import {Link} from 'gatsby';

export default function HeadlinerLink({
                                        color,
                                        text,
                                        linkTo,
                                      }: {color: string; linkTo: string, text: string; sx?: SxProps<Theme>}) {
  const StyledLink = styled(Typography)(({theme}) => ({
    color: color,
    textDecorationColor: color,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecorationColor: theme.palette.secondary.main,
    },
  }));
  return (
    <Link
      to={linkTo}
    ><StyledLink variant={'body1'} fontWeight={800}>{text}</StyledLink>
    </Link>
  );
}
