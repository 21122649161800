import * as React from 'react';
import {SyntheticEvent, useEffect, useRef, useState} from 'react';
import Recaptcha from 'react-google-invisible-recaptcha';
import {Alert, Button, Grid, TextField, Typography, useTheme} from '@mui/material';
import useExpirationBoolean from '../hooks/useExpirationBoolean';
import './hideRecaptchaBadge.css';

const INITIAL_FORM_DATA = {
  name: '',
  email: '',
  message: '',
  _subject: `Novo sporočilo iz spletne strani.`,
  _template: 'table',
};

export default function ContactForm() {
  const [formData, setFormData] = useState<Record<string, string>>(INITIAL_FORM_DATA);
  const [showSuccess, setShowSuccess] = useExpirationBoolean(6000);
  const [showError, setShowError] = useExpirationBoolean(6000);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const formDataRef = useRef(formData);
  const theme = useTheme();
  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  const submitForm = (event: SyntheticEvent) => {
    event.preventDefault();
    setFormSubmitting(true);
    (recaptchaRef.current as any).execute();
  };
  const recaptchaRef = useRef();

  const sendMessage = () => {
    fetch(`https://formsubmit.co/ajax/${process.env.FORMSUBMIT_IO_KEY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(formDataRef.current),
    }).then(response => response.json())
      .then(data => {
        data['success'] === 'false' ? setShowError() : setShowSuccess();
      })
      .catch(error => setShowError())
      .finally(() => {
        setFormData({...INITIAL_FORM_DATA});
        setFormSubmitting(false);
      });
    (recaptchaRef.current as any).reset();
  };

  return (
    <form onSubmit={submitForm} target='_blank'>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <Typography variant={'body1'} fontWeight={'bold'}>Pošljite nam sporočilo</Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item sx={{borderBottom: `3px solid ${theme.palette.secondary.main}`}} xs={6}></Grid>
          </Grid>
        </Grid>
        <Grid item>
          <TextField id='name'
                     name='name'
                     type='text'
                     label='Vaše Ime'
                     value={formData.name}
                     onChange={(e) => setFormData({...formData, name: e.target.value})}
                     variant='outlined'
                     margin='normal'
                     fullWidth
                     required />
          <TextField id='email'
                     name='email'
                     type='email'
                     label='Vaš Email'
                     value={formData.email}
                     onChange={(e) => setFormData({...formData, email: e.target.value})}
                     variant='outlined'
                     margin='normal'
                     fullWidth
                     required />
          <TextField
            id='message'
            name='message'
            label='Vaše Sporočilo'
            variant='outlined'
            margin='normal'
            fullWidth
            multiline
            rows={4}
            value={formData.message}
            onChange={(e) => setFormData({...formData, message: e.target.value})}
            required
          />
          <Recaptcha
            ref={recaptchaRef}
            sitekey={process.env.RECAPTCHA_KEY}
            onResolved={sendMessage} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'caption'} color={'#888'} fontSize={'13px'} textAlign={'right'}>This site is
            protected by reCAPTCHA and
            the Google <a
              href='https://policies.google.com/privacy'>Privacy Policy</a> and <a
              href='https://policies.google.com/terms'>Terms of Service</a> apply.</Typography>
        </Grid>
        <Grid item>
          <Button sx={{backgroundColor: theme.palette.secondary.main, p: '25px 40px'}} disabled={formSubmitting}
                  variant='contained' type='submit' size={'large'}>Pošlji
            sporočilo</Button>
        </Grid>
        <Grid item>
          <Alert sx={{display: showSuccess ? '' : 'none'}} variant='filled' severity='success'>
            Sporočilo je bilo uspešno poslano.</Alert>
          <Alert sx={{display: showError ? '' : 'none'}} variant='filled' severity='error'>Žal je prišlo do
            napake
            pri oddaji sporočila.</Alert>
        </Grid>
      </Grid>
    </form>);
}
